import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'

interface TranslatePayload {
  feature: string
  field: string
  document_id: string
  target_language: string
}

interface TranslateResponse {
  sourceText: string
  sourceLanguage: string
  translations: {
    [key: string]: string
  }
  reliable: number
}

interface DetectLanguagePayload {
  text: string
}

interface DetectLanguageResponse {
  detected_language: string
  confidence: number
}

interface TranslateTextPayload {
  text: string
  target_language: string
}

interface TranslateTextResponse {
  translated_text: string
  source_language: string
}

export const translateContent = async (payload: TranslatePayload): Promise<TranslateResponse> => {
  return api()
    .post(config.routes.translate, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export const detectLanguage = async (payload: DetectLanguagePayload): Promise<DetectLanguageResponse> => {
  return api()
    .post(config.routes.detectLanguage, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export const translateText = async (payload: TranslateTextPayload): Promise<TranslateTextResponse> => {
  return api()
    .post(config.routes.translateText, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
