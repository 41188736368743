// props
//
// submitBtnId : id for submit button
// cancelBtnId : id for cancel button
// okText : ok button text
// cancelText : cancel button text
// title : modal title
// size : modal size ('sm', 'md', 'lg') - defaults to 'sm'
// isPrimary : if true, main button will use primary style instead of danger
// isFullWidthCancel : if true, cancel button will be full width (btn-block)

import React from 'react'
import Modal from 'react-bootstrap4-modal'

class ConfirmPopup extends React.Component {
  handleSubmit(event) {
    event.preventDefault()
    this.props.onOk()
  }
  handleCancel() {
    this.props.onCancel()
  }
  modalBackdropClicked() {
    this.props.modalBackdropClicked()
  }

  getModalClassName() {
    const { size = 'sm', centered = false } = this.props
    const sizeClass = `modal-custom-${size}`
    const centerClass = centered ? 'modal-dialog-centered' : ''
    return `${sizeClass} remove-modal ${centerClass}`.trim()
  }

  getCancelButtonClassName() {
    const { isFullWidthCancel, cancelText } = this.props
    if (isFullWidthCancel) {
      return 'btn btn-xl btn-outline mx-0 mb-10 mb-sm-0 btn-block'
    }
    return `btn btn-xl btn-outline mx-0 mb-10 mb-sm-0 custom-btn-width ${
      cancelText === 'NO' ? 'custom-btn-width-sm' : ''
    }`
  }

  render() {
    const { open, title } = this.props
    return (
      <Modal
        id={this.props.id}
        visible={open}
        onClickBackdrop={this.modalBackdropClicked.bind(this)}
        dialogClassName={this.getModalClassName()}>
        <div className="modal-header border-0 position-relative pb-0">
          <h5 className="modal-title">{title || 'Confirmation'}</h5>
          <button type="button" className="close custom-modal-close-button" onClick={this.handleCancel.bind(this)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pt-5 pb-15">{this.props.children ? this.props.children : null}</div>

        <div className="modal-footer border-0 px-15 pb-15 pt-0 px-sm-30 pb-sm-30 d-block d-sm-flex modal-custom-footer">
          <button
            id={this.props.okID}
            type="button"
            className={`btn btn-xl border-0 ${
              this.props.isPrimary ? 'btn-primary' : 'btn-danger'
            } mx-0 mb-10 mb-sm-0 mr-sm-20 btn-block`}
            onClick={this.handleSubmit.bind(this)}>
            {this.props.okText || 'Ok'}
          </button>
          <button
            id={this.props.cancelID}
            type="button"
            className={this.getCancelButtonClassName()}
            onClick={this.handleCancel.bind(this)}>
            {this.props.cancelText || 'Cancel'}
          </button>
        </div>
      </Modal>
    )
  }
}
export default ConfirmPopup
