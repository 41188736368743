import * as configcat from 'configcat-js'
import config from '@config/index'

/**
 * Format for getting all feature flag values
 */
export interface SegmentFeatureFlagFmt {
  [featureFlag: string]: boolean
}

/**
 * Config cat identifer. please keep this up to date.
 */
interface ConfigCatIdentifier {
  identifier: string
  email: string
  custom: {
    DeviceType: string
  }
}

/**
 * Response from `/api/users/me` a.k.a. getMe()
 */
interface MeData {
  _id: string
  user_email: string
}
export interface FeatureFlag {
  name: string
  default: boolean
}

/**
 * Please keep this updated and prune unused feature flags
 */
export const featureFlags = {
  actionCenterExperiment: {
    name: 'actionCenterExperiment',
    default: false
  },
  instantSiteS3Subdomain: {
    name: 'instantSiteS3Subdomain',
    default: false
  },
  updateCaddyMappingFileApi: {
    name: 'updateCaddyMappingFileApi',
    default: false
  },
  paymentsStatusCard: {
    name: 'paymentsStatusCard',
    default: false
  },
  talkToSalesEnabled: {
    name: 'talkToSalesEnabled',
    default: false
  },
  talkToSalesShown: {
    name: 'talkToSalesShown',
    default: false
  },
  enforceTrialExpiration: {
    name: 'enforceTrialExpiration',
    default: false
  },
  upgradeTrial: {
    name: 'upgradeTrial',
    default: false
  },
  upgradeToProMultiLocation: {
    name: 'upgradeToProMultiLocation',
    default: false
  },
  recurringInvoice: {
    name: 'recurringInvoice',
    default: false
  },
  achPayment: {
    name: 'achPayment',
    default: false
  },
  promoCode: {
    name: 'promoCode',
    default: false
  },
  selfServeSubscriptionCancelation: {
    name: 'selfServeSubscriptionCancelation',
    default: false
  },
  selfServeSubscriptionChange: {
    name: 'selfServeSubscriptionChange',
    default: false
  },
  forceUpgradeAfterTrialExpired: {
    name: 'forceUpgradeAfterTrialExpired',
    default: false
  },
  subscriptionWarningCard: {
    name: 'subscriptionWarningCard',
    default: false
  },
  trialWarningCard: {
    name: 'trialWarningCard',
    default: false
  },
  resumeSubscription: {
    name: 'resumeSubscription',
    default: false
  },
  stripeLivemode: {
    name: 'stripeLiveMode',
    default: false
  },
  yextOptIn: {
    name: 'yextOptIn',
    default: false
  },
  newReviews: {
    name: 'newReviews',
    default: false
  },
  v2fbdatasync: {
    name: 'v2fbdatasync',
    default: false
  },
  v2gmbdatasync: {
    name: 'v2gmbdatasync',
    default: false
  },
  v2qbdatasync: {
    name: 'v2qbdatasync',
    default: false
  },
  gbmEnabled: {
    name: 'gbmEnabled',
    default: false
  },
  gbmPopupEnabled: {
    name: 'gbmPopupEnabled',
    default: false
  },
  autoResponse: {
    name: 'autoResponse',
    default: false
  },
  v2Businesssettings: {
    name: 'v2Businesssettings',
    default: false
  },
  isv2sunsetapi: {
    name: 'isv2sunsetapi',
    default: false
  },
  v2tempqacalendar: {
    name: 'v2tempqacalendar',
    default: false
  },
  showUNB: {
    name: 'showunb',
    default: false
  },
  showEnterpriseUNB: {
    name: 'showenterpriseunb',
    default: false
  },
  v2Thumbtack: {
    name: 'v2Thumbtack',
    default: true
  },
  uberallbanners: {
    name: 'uberallbannersweb',
    default: false
  },
  segregate_invoice_payments: {
    name: 'segregate_invoice_payments',
    default: false
  },
  deleteGositeData: {
    name: 'deletegositedata',
    default: false
  },
  bulkUpdateLoation: {
    name: 'bulkUpdateLoation',
    default: false
  },
  pricingUpdatePlan: {
    name: 'pricingupdateplan',
    default: false
  },
  pricingProjectFlows: {
    name: 'pricingProjectFlows',
    default: false
  },
  enableActionCenter: {
    name: 'enableActionCenter',
    default: true
  },
  showBento: {
    name: 'showBento',
    default: false
  },
  pricingLocationLimitShowContactSales: {
    name: 'pricingLocationLimitShowContactSales',
    default: false
  },
  businessInsurance: {
    name: 'businessInsurance',
    default: false
  },
  wisetack: {
    name: 'wisetack',
    default: false
  },
  uberallOldListing: {
    name: 'uberalloldlisting',
    default: false
  },
  localization: {
    name: 'localization',
    default: false
  },
  webGenAiTranslations: {
    name: 'webGenAiTranslations',
    default: false
  },
  webCustomerServiceAgent: {
    name: 'webCustomerServiceAgent',
    default: false
  }
} as const

// disable logger in production
const clientOptions = {
  logger: config.debug ? configcat.createConsoleLogger(3) : undefined
}

// configCat creates an uncaught error for an undefined (or '') api key - it only catches wrong api key errors so just pass in fake key to handle error gracefully
const configCatClient = configcat.createClient(config.configCat || 'APIKEY', clientOptions)

/**
 * wrapper for configCatClient.getValueAsync
 * @param feature
 * @param identifier
 * @returns
 */
export async function getExperiment(feature: FeatureFlag, identifier?: ConfigCatIdentifier): Promise<boolean> {
  try {
    return await configCatClient.getValueAsync(feature.name, feature.default, identifier)
  } catch (error) {
    return feature.default
  }
}

/**
 * Get all config cat values as an object for use in segment Identifer.
 * @param identifier
 * @returns
 */
export async function getAllExperiments(identifier?: ConfigCatIdentifier): Promise<SegmentFeatureFlagFmt> {
  try {
    const values = await configCatClient.getAllValuesAsync(identifier)
    // turn the experiments from an array into an object
    return values.reduce((acc: SegmentFeatureFlagFmt, value) => {
      acc[value.settingKey] = value.settingValue
      return acc
    }, {})
  } catch (error) {
    return Object.values(featureFlags).reduce((acc: SegmentFeatureFlagFmt, value) => {
      acc[value.name] = value.default
      return acc
    }, {})
  }
}

/**
 * Create a config cat identifier from user data
 * @param me
 * @returns
 */
export function getConfigCatIdFromMe(me: MeData): ConfigCatIdentifier {
  return {
    identifier: me._id,
    email: me.user_email,
    custom: {
      DeviceType: 'browser'
    }
  }
}

/**
 * get a feature flags's value
 * @param feature
 * @param me
 * @returns
 */
export function getFeatureVal(feature: FeatureFlag, me?: MeData) {
  const identifier = me ? getConfigCatIdFromMe(me) : undefined
  return getExperiment(feature, identifier)
}
